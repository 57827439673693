import { Component, OnInit } from '@angular/core';
import { ToastController, NavController } from '@ionic/angular';

@Component({
  selector: 'app-config',
  templateUrl: './config.page.html',
  styleUrls: ['./config.page.scss']
})
export class ConfigPage implements OnInit {
  tab: any = 0;
  form: any = {};

  constructor(
    public toastController: ToastController,
    public navCtrl: NavController
  ) {
    let storage = JSON.parse(localStorage.getItem('config'));
    if (storage) this.form = storage;
  }

  ngOnInit() {}

  segmentChanged(ev: any) {
    this.tab = parseInt(ev.detail.value);
  }

  async submit() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (!this.form.host) {
      const toast = await this.toastController.create({
        message:
          'É necessário informar o HOST para sincronizar as informações.',
        duration: 2000
      });
      toast.present();
    } else {
      localStorage.setItem('config', JSON.stringify(this.form));
      // if (user) this.navCtrl.navigateRoot('home');
      // else
      this.navCtrl.navigateRoot('login');
    }
  }
}
